import React from "react"
import Seo from "../components/site-metadata"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "../components/container"
import Title from "../components/title"
import { injectIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"

const MentionsLegales = ({ intl }) => {
  const title = intl.formatMessage({ id: "mentions-legales.titre" })
  const description = intl.formatMessage({ id: "mentions-legales.description" })
  return (
    <main>
      <Seo title={title} description={description} />
      <Header />
      <Container>
        <Title title={title} />
        <FormattedHTMLMessage id="mentions-legales.content" />
      </Container>
      <Footer />
    </main>
  )
}

export default injectIntl(MentionsLegales)
